/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you lose your hearing, everything changes – from the quality of your social life to your enjoyment of music and your surroundings. Imagine seeking tranquility in the countryside, only to be afflicted by tinnitus, or an eerie silence as you fish by one of the state’s many lakes. And how would attending a Red Sox match be if you couldn’t hear the chants of the crowd? The good news is, many people who experience hearing loss in Massachusetts don’t need to live with poor hearing forever because advanced solutions for hearing loss are now readily available."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "there-are-many-ways-to-damage-your-hearing-in-massachusetts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#there-are-many-ways-to-damage-your-hearing-in-massachusetts",
    "aria-label": "there are many ways to damage your hearing in massachusetts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "There Are Many Ways to Damage Your Hearing in Massachusetts"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As a prosperous, industrialized northeastern state, Massachusetts is full of potential hazards to your hearing. Its economic success has created new threats, from the sounds of heavy machinery to construction sites and traffic on I-95. In the US, 22 million people are exposed to potentially hazardous noise levels at work. But work isn’t the end of the hearing loss story. You can also damage your hearing during leisure hours, even without knowing that you’re putting yourself at risk. For example, around 40,000 people own guns in Massachusetts."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "On average, a gunshot creates between 140 and 190db of noise – well above the level deemed hazardous by most experts (around 85db). But even activities like watching the Red Sox can be a risk to your ears. Baseball crowds can generate as much as 105db, while noise levels are similar in the NFL, where Patriots fans are exposed to 80-90db of noise on a regular basis. You won’t necessarily feel the effects of this noise level immediately, but it does build up over time. The same applies to live music, a major passion in Boston. Whether you’re at Brighton Music Hall or the House of Blues, concerts tend to create 115-120db – more than enough to damage your hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Finally, the urbanized nature of Massachusetts means that almost everyone is exposed to traffic noise. When you realize that a diesel truck can generate 80-90db of noise, you start to understand why the state’s roads are a major contributor to hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-loss-in-massachusetts-a-look-at-the-numbers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-loss-in-massachusetts-a-look-at-the-numbers",
    "aria-label": "hearing loss in massachusetts a look at the numbers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Loss in Massachusetts: A Look at the Numbers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to the Health and Human Services department of Massachusetts, the state is home to an estimated 546,000 people aged 3 years and over who have some degree of hearing loss. While there’s no doubt that age is a factor in the development of hearing loss, according to the NIH, 14% of Americans aged 20-69 have some degree of auditory impairment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "That translates to over 70,000 people in Massachusetts alone! But how many of them have sought help? Surprisingly few. Statistics show that only 16% of those aged 20 to 69 who could benefit from wearing hearing aids choose to do so – which is a shockingly low number."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearcom-can-help-solve-hearing-loss-in-massachusetts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearcom-can-help-solve-hearing-loss-in-massachusetts",
    "aria-label": "how hearcom can help solve hearing loss in massachusetts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How hear.com Can Help Solve Hearing Loss in Massachusetts"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re ready to discover the value of hearing aids, get in touch with our team of experts today. We’ll schedule a fitting for you with one of our Partner Providers (audiologists and hearing care specialists) in your area. We have a network of 80 Partner Providers in Massachusetts alone, so there will always be someone in your neighborhood who can fit you for the right device and give you all the support you need."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-hearcom-and-improve-your-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-hearcom-and-improve-your-hearing",
    "aria-label": "contact hearcom and improve your hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact hear.com and Improve Your Hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Choosing the right hearing aid can change your life, and starting the process couldn’t be easier than with hear.com. Simply fill out our online questionnaire and you’ll receive a free, personal phone consultation with one of our hearing aid experts. After that, we’ll make an appointment for you with one of our local Partner Providers to get your ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing tested"), " and you fitted for your perfect devices. Great hearing is just around the corner with hear.com. Contact us today!"), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
